import { MapDeliveryOptions } from "./enums";

const COMMTXT = {
  chemist2u_delivery_options: {
    [MapDeliveryOptions.FOUR_HOUR]: {
      title: "Same Day Delivery (typically within 4 hours)",
      contentTitle:
        "Our delivery partner typically delivers within 4 hours from the time your order was processed at the pharmacy, between the hours of 9am - 5pm (7 days a week).",
      contentSubtitle: `\nWhile we aim to have orders delivered in the timeframe, delivery times can sometimes vary based on courier availability.\n
            Please be sure you are available to collect your order or have nominated a secure place for your order to be left.\n
            Please note, orders made after 2pm are automatically scheduled for next day delivery, within the chosen delivery option.`
    }
  },
  questionnaire: {
    welcome_nibinternational: {
      left: {
        contents: [
          {
            class_: "col-12 left",
            content: [
              {
                class_: " text-center pt-5 pr-lg-5",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/welcome.png'
              }
            ]
          }
        ]
      },
      right: {
        contents: [
          {
            class_: "col-12 right",
            content: [
              {
                class_: "pt-5 fullwidth-img maxw-400",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/hubhelathniblogo.png'
              }
            ]
          },
          {
            class_: "col-8 col-lg-7 pt-lg-3 pb-lg-3 pt-2 pb-2",
            content: [
              {
                class_: " ",
                type: "hr"
              }
            ]
          },
          {
            class_: "col-1 col-lg-6",
            content: [{}]
          },
          {
            class_: "col-12 pb-lg-4 pb-3 text-sm-center",
            content: [
              {
                class_: " ",
                type: "text",
                tag_: "h2",
                text: "You’re one step closer to taking care of your health. But first, we need to learn more about you. Don’t worry – we’ll keep it short (about 5 mins), and your answers are secure and confidential between you and your doctor."
              }
            ]
          },
          {
            class_: "col-12 pt-3 pb-2",
            content: [
              {
                class_: " strong",
                type: "text",
                tag_: "h3",
                text: "4 easy steps to get your contraceptive pill"
              }
            ]
          },
          {
            class_: "col-3 col-lg-2 pt-3 pb-2 pe-sm-0",
            content: [
              {
                class_: " fullwidth-img-sm-cs maxw-img-85",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/membershipnib.png'
              }
            ]
          },
          {
            class_: "col-9 col-lg-10 pt-3 pb-2 v-center",
            content: [
              {
                class_: " strong",
                type: "text",
                text: "Enter your nib membership number"
              }
            ]
          },
          {
            class_: "col-3 col-lg-2 pt-3 pb-2 pe-sm-0",
            content: [
              {
                class_: " fullwidth-img-sm-cs maxw-img-85",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/tellusnib.png'
              }
            ]
          },
          {
            class_: "col-9 col-lg-10 pt-3 pb-2 v-center",
            content: [
              {
                class_: " strong",
                type: "text",
                text: "Answer a few questions about your health"
              }
            ]
          },
          {
            class_: "col-3 col-lg-2 pt-3 pb-2 pe-sm-0",
            content: [
              {
                class_: " fullwidth-img-sm-cs maxw-img-85",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/consultnib.png'
              }
            ]
          },
          {
            class_: "col-9 col-lg-10 pt-3 pb-2 v-center",
            content: [
              {
                class_: " strong",
                type: "text",
                text: "Our medical team review your health profile"
              }
            ]
          },
          {
            class_: "col-3 col-lg-2 pt-3 pb-2 pe-sm-0",
            content: [
              {
                class_: " fullwidth-img-sm-cs maxw-img-85",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/deliverordernib.png'
              }
            ]
          },
          {
            class_: "col-9 col-lg-10 pt-3 pb-2 v-center",
            content: [
              {
                class_: " strong",
                type: "text",
                text: "Delivered fast and free in discreet packaging"
              }
            ]
          },
          {
            class_: "col-12",
            content: [
              {
                type: "customize",
                subtype: "nibinternational",
                class_: "nibinternational-customize mt-8 pt-8 btlr-08 btrr-08",
                innerclass_: " pl-sm-1 pr-sm-1 pl-6-cs pr-6-cs",
                header: {
                  class_: " pb-5 strong",
                  content:
                    "Please provide your details below so we can identify your account"
                }
              }
            ]
          }
        ]
      }
    },
    welcome_hubhealth: {
      left: {
        contents: [
          {
            class_: "col-12 left",
            content: [
              {
                class_: " text-center pt-5 pr-lg-5",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/welcome.png'
              }
            ]
          }
        ]
      },
      right: {
        contents: [
          {
            class_: "col-12 right",
            content: [
              {
                class_: "pt-5 fullwidth-img maxw-400",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/welcomelogo.png'
              }
            ]
          },
          {
            class_: "col-8 col-lg-7 pt-lg-3 pb-lg-3 pt-2 pb-2",
            content: [
              {
                class_: " ",
                type: "hr"
              }
            ]
          },
          {
            class_: "col-1 col-lg-6",
            content: [{}]
          },
          {
            class_: "col-12 pb-lg-4 pb-3",
            content: [
              {
                class_: " ",
                type: "text",
                tag_: "h3",
                text: "You’re one step closer to taking care of your health. But first, we need to learn more about you. Don’t worry – we’ll keep it short and sweet (about 5 mins), and your answers are secure and confidential between you and your doctor."
              }
            ]
          },
          {
            class_: "col-12 pt-3 pb-2",
            content: [
              {
                class_: " strong",
                type: "text",
                tag_: "h3",
                text: "3 easy steps to getting sorted!"
              }
            ]
          },
          {
            class_: "col-3 col-lg-2 pt-3 pb-2 pe-sm-0",
            content: [
              {
                class_: " fullwidth-img-sm-cs maxw-img-85",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/tellus.png'
              }
            ]
          },
          {
            class_: "col-9 col-lg-10 pt-3 pb-2 v-center",
            content: [
              {
                class_: " strong",
                type: "text",
                text: "Answer some simple questions"
              },
              {
                class_: " ",
                type: "text",
                text: "Answer some simple questions online, anytime.",
                hide: { name: "isver", value: "yes" }
              }
            ]
          },
          {
            class_: "col-3 col-lg-2 pt-3 pb-2 pe-sm-0",
            content: [
              {
                class_: " fullwidth-img-sm-cs maxw-img-85",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/consult.png'
              }
            ]
          },
          {
            class_: "col-9 col-lg-10 pt-3 pb-2 v-center",
            content: [
              {
                class_: " strong",
                type: "text",
                text: "Our Australian-based doctors review your answers online"
              },
              {
                class_: " ",
                type: "text",
                text: "They’ll advise what’s right for you and may discuss possible treatments with you by text.",
                hide: { name: "isver", value: "yes" }
              }
            ]
          },
          {
            class_: "col-3 col-lg-2 pt-3 pb-2 pe-sm-0",
            content: [
              {
                class_: " fullwidth-img-sm-cs maxw-img-85",
                type: "img",
                path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/deliverorder.png'
              }
            ]
          },
          {
            class_: "col-9 col-lg-10 pt-3 pb-2 v-center",
            content: [
              {
                class_: " strong",
                type: "text",
                text: "We’ll deliver treatments to your door"
              },
              {
                class_: " ",
                type: "text",
                text: "Fast and discreet delivery to your door.",
                hide: { name: "isver", value: "yes" }
              }
            ]
          }
        ]
      }
    },
    membershiperror:
      "The nib membership details provided could not be found. Please check your membership number and date of birth carefully to try again."
  },
  messagetype: [
    {
      title: "I want to check interactions between my medications",
      to: "nurse"
    },
    {
      title: "I want to try a different treatment",
      to: "nurse"
    },
    {
      title: "I want to modify my dosage",
      to: "nurse"
    },
    {
      title: "I’m experiencing side effects",
      to: "nurse"
    },
    {
      title: "I have a medical question",
      to: "nurse"
    },
    {
      title: "I need help with my order",
      to: "order",
      nocontent: true
    },
    {
      title: "Other",
      to: "other"
    }
  ],
  ordermessagetype: [
    {
      title: "I want to turn of my auto refills",
      to: "pharmacy",
      contents: [
        {
          content: "To turn off your auto refills:",
          class_: "mb-2"
        },
        {
          content:
            '1.Go to {"type":"link", "text": "Treatments", "data": "/treatments"}',
          class_: "pl-1"
        },
        {
          content: "2.Click on Manage Repeats",
          class_: "pl-1"
        },
        {
          content: "3.Switch the Auto-Refills toggle from Yes to No.",
          class_: "pl-1 mt-1"
        },
        {
          content:
            "Note: By doing this, you will no longer receive refill reminders from Youly, and you will need to manually order refills when required.",
          class_: "mt-2"
        },
        {
          content:
            "If you are still experiencing issues with turning off auto repeats, please contact our support team for further assistance.",
          class_: "mt-1"
        }
      ]
    },
    {
      title: "I want to cancel this month’s shipment",
      to: "doctor",
      contents: [
        {
          content: "To cancel the next scheduled shipment:",
          class_: "mb-2"
        },
        {
          content:
            '1.Go to {"type":"link", "text": "Treatments", "data": "/treatments"}',
          class_: "pl-1"
        },
        {
          content: "2.Click on Manage Repeats",
          class_: "pl-1"
        },
        {
          content:
            "3.Where it shows the date for Next Refill Scheduled, click Edit.",
          class_: "pl-1 mt-1"
        },
        {
          content:
            "Note: This will delay your next shipment until the date you choose. After that, your automatic shipments will continue as they did before.",
          class_: "mt-2"
        },
        {
          content:
            "If you are still experiencing issues with turning off auto repeats, please contact our support team for further assistance.",
          class_: "mt-1"
        }
      ]
    },
    {
      title: "I want to track my order",
      to: "doctor",
      contents: [
        {
          content: "To track your order:",
          class_: "mb-2"
        },
        {
          content:
            '1.Go to {"type":"link", "text": "History", "data": "/history"}',
          class_: "pl-1"
        },
        {
          content:
            "2.Select Track Order. This will send you to the Australia Post website, where you can track your order directly.",
          class_: "pl-1 mt-1"
        },
        {
          content:
            "If you are still experiencing issues with tracking your order, please contact our support team for further assistance.",
          class_: "mt-2"
        }
      ]
    },
    {
      title: "I want to change my refill quantity",
      to: "nurse",
      contents: [
        {
          content: "To change your refill quantity:",
          class_: "mb-2"
        },
        {
          content:
            '1.Go to {"type":"link", "text": "Treatments", "data": "/treatments"}',
          class_: "pl-1"
        },
        {
          content: "2.Click on Manage Repeats"
        },
        {
          content:
            "3.Where it shows your Refill Qty, click Edit. This allows you to select from a variety of refill quantity options.",
          class_: "pl-1 mt-1"
        },
        {
          content:
            "If you are still experiencing issues with changing your refill quantity, please contact our support team for further assistance.",
          class_: "mt-2"
        }
      ]
    },
    {
      title: "I want to update my personal information",
      to: "nurse",
      contents: [
        {
          content: "To update your personal information:",
          class_: "mb-2"
        },
        {
          content:
            '1. Go to {"type":"link", "text": "Profile", "data": "/profile"}',
          class_: "pl-1"
        },
        {
          content: "2. Click Edit on the section you wish to update.",
          class_: "pl-1 mt-1"
        },
        {
          content:
            "We ask you to please ensure that your personal information is correct before submitting an order. If you are still experiencing issues with updating your personal information, please contact our support team for further assistance.",
          class_: "mt-2"
        }
      ]
    },
    {
      title: "I want to update my payment details",
      to: "order",
      contents: [
        {
          content: "To update your personal information:",
          class_: "mb-2"
        },
        {
          content:
            '1.Go to {"type":"link", "text": "Profile", "data": "/profile"}',
          class_: "pl-1"
        },
        {
          content: "2.Click Edit on the section you wish to update.",
          class_: "pl-1 mt-1"
        },
        {
          content:
            "We ask you to please ensure that your personal information is correct before submitting an order. If you are still experiencing issues with updating your personal information, please contact our support team for further assistance.",
          class_: "mt-2"
        }
      ]
    },
    {
      title: "Other",
      to: "other"
    }
  ],
  termofuse: {
    value: "https://hub.health/terms-and-conditions/"
  },
  agreement: {
    value: "https://hub.health/patient-consent-agreement/"
  },
  privacy: {
    value: "https://hub.health/privacy-policy/"
  },
  notifications: {
    guarantee_1: "Free Script Renewal",
    guarantee_2: "Renew your script for free with a short questionnaire.",
    buttons: [
      {
        type: "next",
        from: "doctor",
        text: "Continue to medication details"
      },
      {
        type: "next",
        from: "pharmacy",
        text: "I have read and understood all of the above information"
      },
      {
        type: "send",
        text: "Message your medical team"
      }
    ],
    guarantees: [
      {
        name: "no1",
        contents: [
          {
            text: "We've treated"
          },
          {
            tag_: "h1",
            class_: "strong",
            text: "40,000+"
          },
          {
            text: "Happy patients"
          }
        ]
      }
    ],
    medicationdetails: {
      contents: [
        {
          valueclass_: "strong-5",
          label: "Medication",
          value: "medication"
        },
        {
          valueclass_: "strong-5",
          label: "Price",
          value: "price"
        },
        {
          valueclass_: "strong-5",
          label: "Details",
          value: "detail",
          hide_for_otc: true
        },
        {
          valueclass_: "strong-5",
          label: "Directions*",
          value: "direction"
        },
        {
          valueclass_: "strong-5",
          label: "Quantity",
          value: "qty",
          hide_for_otc: true
        },
        {
          valueclass_: "strong-5",
          label: "Delivery Cost",
          include: "fill-a-script",
          value: "deliverycost",
          text: '${"type":"text", "field": "yes", "data": "deliverycost"} Express Shipping',
          hide_for_otc: true
        },
        {
          valueclass_: "strong-5",
          label: "Delivery Cost",
          exclude: "fill-a-script",
          text: '${"type":"text", "field": "yes", "data": "shippingCost"} Express Shipping',
          hide_for_otc: true
        },
        {
          valueclass_: "strong-5",
          textgroupclass_: "m-0",
          label: "Includes",
          textgroup: [
            {
              text: "Express delivery",
              exclude: "fill-a-script",
              show_for_nib: false
            },
            { text: "Discreet Packaging" },
            {
              text: "Auto-renewals (change or cancel any time)",
              cond: "not",
              field: "order_on_demand",
              value: "Yes"
            }
          ]
        },
        {
          valueclass_: "strong-5",
          label: "Delivery",
          include: "contraceptive pill",
          /** Shows only if the patient is an NIB International member */
          show_for_nib: true,
          value: "delivery",
          textgroup: [
            { text: "Express delivery fee waived for nib Members (save $10)" }
          ]
        },
        {
          valueclass_: "strong-5",
          label: "eScript Token",
          value: "escripttoken",
          text: '{"type":"text", "field": "yes", "data": "escripttoken"}'
        }
      ]
    },
    confirm: {
      beforecheckbox: {
        contents: [
          {
            text: "Please confirm before proceeding"
          }
        ]
      },
      checkboxs: [
        {
          contents: [
            /*
            {
              text: `I have read and understood all of the information that has been provided to me. I do not have any further questions*. I understand and consent to my treatment plan.`
            }
          */
            {
              text: "I have read and understood all of the information that has been provided to me."
            },
            {
              text: "I do not have any further questions*."
            },
            {
              text: "I understand and consent to my treatment plan."
            }
          ],
          name: "ckbox_1"
        }
      ],
      aftercheckbox: {
        contents: [
          {
            text: "If you have any questions or concerns about this Treatment Plan, please click here to message your Medical Team."
          }
        ]
      },
      notice_1: {
        contents: [
          {
            text: "*Always refer to your medication label for your personalised directions. If you have any questions or concerns, please contact your Medical Team via the portal."
          },
          {
            text: "Please note the cost of medication shown on the receipt cannot be claimed from your health insurer.",
            show_for_nib: true
          }
        ]
      }
    },
    docletter: {
      contents: [
        {
          class_: " strong data-hj-suppress",
          text: 'Hi {"type":"text", "field": "yes", "data": "patient_name"},'
        },
        {
          text: "Thanks for providing the info required to prescribe your treatment plan."
        },
        {
          text: ""
        },
        {
          text: 'Based on the info you’ve shared, I’ve prescribed {"type":"text", "field": "yes", "data": "display_name"},'
        },
        {
          text: "Before you begin your journey to better health, it’s important that you understand the following information regarding your treatment:"
        },
        {
          text: ""
        },
        {
          class_: " strong m-0",
          text: "Treatment order:"
        },
        {
          text: '{"type":"text", "field": "yes", "data": "directions"}'
        },
        {
          text: ""
        },
        {
          text: ""
        },
        {
          text: "Keep in mind: not everyone will experience side effects, however they are a possibility. If you have any concerns, contact a medical professional as soon as possible."
        },
        {
          text: ""
        },
        {
          text: "Feel free to reach out via your patient portal at any time."
        },
        {
          text: ""
        },
        {
          text: "Yours in health,"
        },
        {
          suffix: "NP",
          text: '{"type":"text", "field": "yes", "data": "docname"}'
        }
      ]
    }
  },
  notifications_noncurrect: {
    guarantee_1: "Free Script Renewal",
    guarantee_2: "Renew your script for free with a short questionnaire."
  },
  notifications_repeat: {
    guarantee_1: "Free Script Renewal",
    guarantee_2: "Renew your script for free with a short questionnaire."
  },
  newnotifications: {
    uploadscript: {
      desc: "Please upload a photo image of your script using the button above. It needs to be a clear photo of either the electronic script barcode, or the physical paper script, it can't be a photo of the box or a pharmacy receipt."
    },
    noncurrent: {
      desc: "One of our Medical Team will review and issue your script within 12 hours! From there you can order your treatment."
    },
    repeats: {
      stageall_1:
        "Don't worry, our Medical Team just require a quick medical check in to issue your new script. It’s text based and only takes a couple of minutes. ",
      stageall_2:
        "Your wellbeing is our priority, so it's important we get updated healthcare information to make sure this treatment is right for you.",
      stageall_3:
        "Medical checkins also give you the opportunity to connect with your Medical Team, ask any questions, or even change treatments.",
      stageall_4:
        "Please click the button above to start the check in, as we won't be able to continue sending your treatment until you do :)",
      stage1: "You've used up all your repeats!",
      stage2: "Your script has expired!",
      stage3: "You're on your last repeat!",
      stage4_1_1: "Your script is expiring on",
      stage4_1_2: "Your script is expiring soon!",
      stage4_2: "Your script for [data.product] has expired.",
      stage4_3:
        "Our Medical Team require a quick medical check in to issue your new one.",
      stage4_4:
        "It's completely free, text based and shouldn't take more than a few minutes. Your wellbeing is important to us so it's important we get some up to date information to make sure this treatment is right for you.",
      stage4_5:
        "It also gives you the opportunity to connect with your Medical Team, ask any questions, or even change treatments.",
      stage4_6:
        "Please click the button above to complete, as we won't be able to send any further medication until you do :)"
    }
  },
  replacestring: [
    {
      name: "[brand]",
      varname: "brand_pill",
      key: "title"
    }
  ],
  products: {
    warning: {
      refill:
        "Are you sure you want to change this? Changing your refill quantity and frequency may result in a transaction if this is close to your next supply date"
    },
    non_current: {
      header:
        "Your information is securely stored and ready for the medical team to review. Click the button shown to submit for your consultation, and the medical team will review within 24 hours.",
      pill: "Treatment plan not yet created/approved",
      act: "Click below to submit for your consultation",
      bttxt: "Submit Consultation"
    },
    doctor_upload: {
      header: " is waiting to receive your script.",
      pill: "Treatment plan not yet created/approved",
      act: "Click below to upload your script",
      bttxt: "UPLOAD SCRIPT"
    },
    pharmacy_upload: {
      header: " is waiting to receive your script.",
      pill: "Treatment plan not yet created/approved",
      act: "Click below to upload your script",
      bttxt: "UPLOAD SCRIPT"
    },
    doctor_verifying: {
      header:
        " is reviewing your treatment. We'll let you know when it's ready, usually within 24 hours. You'll receive a notification if Medical Team has any follow-up questions for you.",
      pill: "Treatment plan not yet created/approved"
    },
    pharmacy_verifying: {
      header:
        " is verifying your script. We'll let you know when it's ready, usually within 24 hours. You'll receive a notification if the Pharmacist has any follow-up questions for you.",
      pill: "Treatment not yet approved by Pharmacist"
    },
    doctor_verified: {
      header:
        "Your request has been reviewed and your treatment plan has been issued. After you review and accept your treatment plan, you can place an order and we will deliver your medication directly to your door.",
      pill: "exist",
      act: "Click below to confirm and pay for your treatment.",
      bttxt: "View your treatment"
    },
    pharmacy_verified: {
      header:
        "Your request has been reviewed and your treatment plan has been issued. After you review and accept your treatment plan, you can place an order and we will deliver your medication directly to your door.",
      pill: "exist",
      act: "Click below to confirm and pay for your treatment.",
      bttxt: "View your treatment"
    },
    // Order has "Pending" status
    doctor_payment_pending: {
      header:
        'We have encountered an issue with your default payment method. Please update your payment details here in your {"type":"link", "text": "Profile", "data": "/profile"} as soon as possible.'
    },
    // Order has "Pending" status
    pharmacy_payment_pending: {
      header:
        'We have encountered an issue with your default payment method. Please update your payment details here in your {"type":"link", "text": "Profile", "data": "/profile"} as soon as possible.'
    },
    // doctor_payment_failed: {
    //   header:
    //     " has approved your prescription but payment was unsuccessful. We will attempt to process payment again in 24 hours using your nominated payment details."
    // },
    // pharmacy_payment_failed: {
    //   header:
    //     " has approved your prescription but payment was unsuccessful. We will attempt to process payment again in 24 hours using your nominated payment details."
    // },
    // Order has "Paid" status
    // doctor_paid: {
    //   header:
    //     "Your order is being prepared by the pharmacy and will be dispatched soon.",
    //   noTitle: true
    // },
    // Order has "Paid" status
    // pharmacy_paid: {
    //   header:
    //     "Your order is being prepared by the pharmacy and will be dispatched soon.",
    //   noTitle: true
    // },
    // // Order has "Bad Address" status
    // doctor_bad_address: {
    //   header:
    //     'Important: We were unable to dispatch your order as your address appears to be invalid. Please update your address here in your {"type":"link", "text": "Profile", "data": "/profile"} as soon as possible.',
    //   noTitle: true
    // },
    // // Order has "Bad Address" status
    // pharmacy_bad_address: {
    //   header:
    //     'Important: We were unable to dispatch your order as your address appears to be invalid. Please update your address here in your {"type":"link", "text": "Profile", "data": "/profile"} as soon as possible.',
    //   noTitle: true
    // },
    // Order has "Error" status
    doctor_error: {
      header:
        "Important: Our system indicates something went wrong with your order, please reach out to our support team asap.",
      noTitle: true
    },
    // Order has "Error" status
    pharmacy_error: {
      header:
        "Important: Our system indicates something went wrong with your order, please reach out to our support team asap.",
      noTitle: true
    },
    // Order has "Being Processed" status
    // doctor_being_processed: {
    //   header:
    //     "Your order is being prepared by the pharmacy and will be dispatched soon.",
    //   noTitle: true
    // },
    // Order has "Being Processed" status
    // pharmacy_being_processed: {
    //   header:
    //     "Your order is being prepared by the pharmacy and will be dispatched soon.",
    //   noTitle: true
    // },
    // // Order has "Cold Chain - Being Processed" status
    // doctor_cold_chain_being_processed: {
    //   header:
    //     "We're arranging cold chain shipping for your order, and it's set to be picked up by our delivery partner within 2 business days. Expect an order update via SMS as soon as it's on its way.",
    //   noTitle: true
    // },
    // // Order has "Cold Chain - Being Processed" status
    // pharmacy_cold_chain_being_processed: {
    //   header:
    //     "We're arranging cold chain shipping for your order, and it's set to be picked up by our delivery partner within 2 business days. Expect an order update via SMS as soon as it's on its way.",
    //   noTitle: true
    // },
    // Order has "Compounding in Progress" status
    // doctor_compounding_in_progress: {
    //   header:
    //     "Your medicine is currently in our compounding lab, where we make each unique formulation for you. This can take 1-2 business days.",
    //   noTitle: true
    // },
    // Order has "Compounding in Progress" status
    // pharmacy_compounding_in_progress: {
    //   header:
    //     "Your medicine is currently in our compounding lab, where we make each unique formulation for you. This can take 1-2 business days.",
    //   noTitle: true
    // },
    // The most recent order is in "Waiting" status
    // doctor_waiting: {
    //   header:
    //     "Your order is currently waiting for new stock to arrive. We apologise for any inconvenience and will endeavour to process as soon as possible.",
    //   noTitle: true
    // },
    // The most recent order is in "Waiting" status
    // pharmacy_waiting: {
    //   header:
    //     "Your order is currently waiting for new stock to arrive. We apologise for any inconvenience and will endeavour to process as soon as possible.",
    //   noTitle: true
    // },
    // A new script is required for this subscription. At this point, a repeat questionnaire will have been generated to complete
    doctor_new_script_required: {
      header:
        "The Pharmacist is waiting for a new script to be issued. Please click Renew or Review button or if done so already, the Doctor will issue this in the next 24 hours.",
      pill: "exist",
      bttxt: "Renew or Change Script",
      act: "Renew your script",
      act_add:
        "Before we can dispense your next order, our Doctors need to check in with you and review your treatment plan.",
      act_class: " red"
    },
    // A new script is required for this subscription. At this point, a repeat questionnaire will have been generated to complete
    pharmacy_new_script_required: {
      header:
        "The Pharmacist is waiting for a new script to be issued. Please click Renew or Review button or if done so already, the Doctor will issue this in the next 24 hours.",
      pill: "exist",
      bttxt: "Renew or Change Script",
      act: "Renew your script",
      act_add:
        "Before we can dispense your next order, our Doctors need to check in with you and review your treatment plan.",
      act_class: " red"
    },
    // The most recent order is in "Cancelled" status
    doctor_cancelled: {
      header: "Your previous order has been cancelled",
      pill: "exist",
      bttxt: "MANAGE REPEATS",
      act: true
    },
    // The most recent order is in "Cancelled" status
    pharmacy_cancelled: {
      header: "Your previous order has been cancelled",
      pill: "exist",
      bttxt: "MANAGE REPEATS",
      act: true
    },
    doctor_purchase: {
      noprefix: true,
      header: " is preparing your treatment.",
      pill: "exist"
    },
    pharmacy_purchase: {
      noprefix: true,
      header: " is preparing your treatment.",
      pill: "exist"
    },
    // doctor_dispatch: {
    //   header:
    //     "Your treatment plan has been issued. If you have any queries regarding your treatment or would like to request a change to your treatment plan, please contact our Patient Success team.",
    //   pill: "exist",
    //   bttxt: "MANAGE REPEATS",
    //   act: true
    // },
    // pharmacy_dispatch: {
    //   header:
    //     "Your treatment plan has been issued. If you have any queries regarding your treatment or would like to request a change to your treatment plan, please contact our Patient Success team.",
    //   pill: "exist",
    //   bttxt: "MANAGE REPEATS",
    //   act: true
    // },
    doctor_repeat: {
      header: " approved your treatment.",
      pill: "exist",
      bttxt: "Renew or Change Script",
      act: "Renew your script",
      act_add:
        "Before we can dispense your next order, our Doctors need to check in with you and review your treatment plan.",
      act_class: " red"
    },
    pharmacy_repeat: {
      header: " approved your treatment.",
      pill: "exist",
      bttxt: "Renew or Change Script",
      act: "Renew your script",
      act_add:
        "Before we can dispense your next order, our Doctors need to check in with you and review your treatment plan.",
      act_class: " red"
    },
    doctor_repeatreview: {
      header:
        "Before we can approve your request, one of our clinicians needs to complete a quick call with you. Use the ‘Book an appointment’ button to choose a time for this call that suits you.",
      pill: "exist"
    },
    pharmacy_repeatreview: {
      header:
        " is verifying your script. We'll let you know when it's ready, usually within 24 hours. You'll receive a notification if the Pharmacist has any follow-up questions for you.",
      pill: "exist"
    },
    doctor_cancel: {
      header: " approved your treatment.",
      pill: "exist",
      bttxt: "Subscription is cancelled",
      act: true
    },
    pharmacy_cancel: {
      header: " approved your treatment.",
      pill: "exist",
      bttxt: "Subscription is cancelled",
      act: true
    },
    doctor_decline: {
      header: " has not approved your treatment.",
      pill: "No treatment prescribed",
      feedback:
        'The {"type": "cond", "cond": "eq", "field": "nurse_practitioner", "nok": "doctor", "ok": "nurse practitioner"} hasn\'t provided any feedback at this time.',
      act: true
    },
    pharmacy_decline: {
      header: " has not approved your treatment.",
      pill: "No treatment prescribed",
      feedback: "The Pharmacist hasn't provided any feedback at this time.",
      act: true
    },
    // show_accept: {
    //   header:
    //     " has approved your repeat script - it's now ready for purchase and delivery to your door! Hit the button to confirm your new repeat treatment plan and your order.",
    //   pill: "exist",
    //   act: "Click below to confirm for your new repeat treatment.",
    //   bttxt: "TREATMENT ACCEPTANCE"
    // },
    pathology_request: {
      header:
        " has requested you undertake a pathology test before determining your treatment. Hit the button below to download your pathology request and bring it to your nearest pathology lab to get tested. The results will be directly sent to the doctor.",
      pill: "exist",
      act: "Click below to download your pathology request.",
      bttxt: "DOWNLOAD PATHOLOGY REQUEST"
    },
    image_request: {
      header:
        "Please upload a photo of yourself to continue with your consultation. The images you share are confidential between you and your assigned clinician and are used only to assess your needs and provide a personalised treatment plan."
    },
    medicalteam_verifying: {
      header:
        "We have received your request and will assign this to a clinician shortly. Your treatment plan will typically be issued within 24 hours and your clinician will contact you if they have any questions."
    },
    medicalteam_verifyid: {
      header:
        "Verification required: We need to verify your identity to proceed with your consultation. Please select one of the following options to continue."
    }
    // blood_pressure_request: {
    //   headerIcon: "IconInformationCircle",
    //   headerTitle: "Blood pressure required",
    //   header:
    //     " Please provide a blood pressure reading to continue with your consultation. You can have your blood pressure checked at most pharmacies or use a home blood pressure cuff.",
    //   headerButton: "Input reading"
    // }
    // upcoming_renewal: {
    //   header:
    //     "We will invite you to renew your treatment on active_date. If you need to renew sooner, please contact us."
    // }
  },
  faqs: {
    faqs: [
      {
        question: "What is your ongoing support?",
        answer:
          "The same as your regular GP or Pharmacist, but without the appointments or waiting rooms! You can schedule consultations, ask questions and change treatments whenever you like, at no extra cost."
      },
      {
        question: "Can I cancel at any time?",
        answer: `Yes, you can cancel anytime, however if you have medication that has already been dispensed then you will still be charged for that medication. Please note that if you cancel your Treatment Plan, you’ll need to complete another assessment  if you want to re-order in future. You may prefer to simply switch from automatic to manual refills in your Patient Portal, which will keep the Treatment Plan active until its expiry date.
						 To switch to manual refills, {"type":"click", "text": "click here", "data": "refill"}. Or to cancel, {"type": "click", "text":"click here", "data": "cancel"}.`
      },
      {
        question: "Can I change my Treatment Plan?",
        answer: `Of course! Just 
	   					{"type":"link", "text": "contact your Medical Team", "data": "/team"} and we can amend your plan as necessary, at no extra cost.`
      },
      {
        question: "How do I chat with my Medical Team?",
        answer: `Ongoing and unlimited medical consultations are all a part of using our service. Head to 
						{"type":"link", "text": "My Medical Team", "data": "/team"} in your Patient Portal to get in touch. We’re more than happy to help!`
      },
      {
        question: "How do I know if this medication is right for me?",
        answer:
          "Your treatment notes outline why the medical team has provided this treatment recommendation for you. Still unsure? No worries. You can chat further with your Doctor as often as you need at no extra cost. Simply message your Medical Team with any questions you may have."
      }
    ],
    cancelreasons: [
      {
        feedback: 1,
        text: "I have enough medication already"
      },
      { feedback: 2, text: "I’m not getting the results I wanted/expected" },
      {
        feedback: 3,
        text: "It’s too expensive"
      },
      {
        feedback: 4,
        text: "I’m experiencing side effects"
      },
      {
        feedback: 5,
        text: "I’m not satisfied with this service"
      },
      {
        feedback: 6,
        text: "Other (please specify)"
      }
    ],
    feedback: [
      {
        id: 1,
        text: "Would you like to delay your next repeat or disable automatic refills instead?",
        type: "button",
        buttons: [
          { text: "Yes, I’d like to delay my next repeat", fn: "refill" },
          { text: "Yes, I’d like to switch to manual refills", fn: "manual" },
          { text: "No thanks, I just want to cancel", fn: "cancel" }
        ]
      },
      {
        id: 2,
        text: "Would you like to consult with your Medical Team? There’s no extra cost to you, and there may be other treatment options that could work better for you.",
        type: "button",
        buttons: [
          { text: "Yes, I’d like to consult with my Medical Team", fn: "team" },
          { text: "No thanks, I just want to cancel", fn: "cancel" }
        ]
      },
      {
        id: 3,
        text: "Would you like to consult with your Medical Team? They may be able to recommend a more affordable treatment for you.",
        type: "button",
        buttons: [
          { text: "Yes, I’d like to consult with my Medical Team", fn: "team" },
          { text: "No thanks, I just want to cancel", fn: "cancel" }
        ]
      },
      {
        id: 4,
        text: "Would you like to consult with your Medical Team? They may be able to recommend a treatment option with lower risk of side effects.",
        type: "button",
        buttons: [
          { text: "Yes, I’d like to consult with my Medical Team", fn: "team" },
          { text: "No thanks, I just want to cancel", fn: "cancel" }
        ]
      },
      {
        id: 5,
        text: "If you think there’s any way we could improve our service, please let us know below.",
        type: "textarea",
        textareaid: "txt1",
        buttons: [{ text: "submit", fn: "cancel" }]
      },
      {
        id: 6,
        text: "Would you be able to provide a little more information? This will help us improve our service in future.",
        type: "textarea",
        textareaid: "txt2",
        buttons: [{ text: "submit", fn: "cancel" }]
      }
    ]
  }
};

export default COMMTXT;
